import React from "react";
import "./terms.scss";
const Terms = () => {
  return (
    <div className="flex mt-[150px] mx-auto w-[400px] md:w-[800px] mb-8   ">
      <div className="terms w-[100%]">
        <div>
          <h1 className="md:text-[32px] text-[20px] font-bold mb-8">
            {" "}
            الشروط والاحكام
          </h1>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px]  md:text-[16px]">
            مقدمة
          </h3>
          <p className="text-[12px] md:text-[14px]">
            باستخدامك بطاقة مهم (يشار إليها فيما بعد بـ "البطاقة" أو بطاقة مهم)،
            فإنك توافق على الالتزام بهذه الشروط والأحكام. إذا كنت لا توافق على
            أي جزء من هذه الشروط، يُرجى الامتناع عن استخدام البطاقة.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            صلاحية البطاقة
          </h3>
          <p className="text-[12px] md:text-[14px]">
            البطاقة صالحة لمدة سنة واحدة من تاريخ إصدارها ويمكن تجديدها سنويًا
            بناءً على رغبة العميل. يجب على المستخدم التحقق من تاريخ انتهاء
            صلاحية البطاقة قبل كل استخدام.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            {" "}
            الاستخدام في المستشفيات والمراكز الطبية المشاركة
          </h3>
          <p className="text-[12px] md:text-[14px]">
            البطاقة توفر خصومات على الخدمات الطبية فقط في المستشفيات والمراكز
            الطبية المشاركة في البرنامج. للحصول على قائمة بأماكن الاستخدام
            المسموح بها، يُرجى زيارة الموقع الإلكتروني أو التواصل مع خدمة
            العملاء.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            نطاق الخصومات
          </h3>
          <p className="text-[12px] md:text-[14px]">
            يختلف مقدار الخصم بناءً على الخدمة الطبية المقدمة والمنشأة التي
            تقدمها. يُرجى مراجعة تفاصيل الخصومات المتاحة في كل مركز طبي قبل
            استخدام البطاقة.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            الخدمات المشمولة وغير المشمولة{" "}
          </h3>
          <p className="text-[12px] md:text-[14px]">
            لا تشمل البطاقة جميع الخدمات الطبية. يتم استثناء بعض العلاجات
            والعمليات الجراحية والمستلزمات الطبية. يمكن الاطلاع على القائمة
            الكاملة للخدمات المشمولة وغير المشمولة عبر الموقع الإلكتروني الخاص
            بالبرنامج.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            استخدام البطاقة
          </h3>
          <p className="text-[12px] md:text-[14px]">
            - البطاقة مخصصة للاستخدام الشخصي وغير قابلة للتحويل إلى شخص آخر. -
            يجب تقديم البطاقة قبل الحصول على الخدمة الطبية للحصول على الخصم. -
            يحتفظ مقدم الخدمة بحق رفض الخصم إذا لم يتم تقديم البطاقة قبل الخدمة.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            *المسؤولية*
          </h3>
          <p className="text-[12px] md:text-[14px]">
            لا تتحمل الجهة المصدرة للبطاقة المسؤولية عن جودة الخدمات المقدمة في
            المستشفيات والمراكز الطبية المشاركة، حيث أن البطاقة تهدف فقط لتوفير
            خصم على السعر الأصلي.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            التعديلات على الشروط والأحكام
          </h3>
          <p className="text-[12px] md:text-[14px]">
            نحتفظ بحق تعديل هذه الشروط والأحكام في أي وقت. سيتم إخطار المستخدمين
            بأي تعديلات جوهرية من خلال الموقع الإلكتروني أو عبر البريد
            الإلكتروني.
          </p>
        </div>
        <div>
          <h1 className="md:text-[32px] text-[20px] font-bold mb-8">
            {" "}
            سياسة الخصوصية{" "}
          </h1>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            جمع المعلومات الشخصية
          </h3>
          <p className="text-[12px] md:text-[14px]">
            يتم جمع المعلومات الشخصية من العملاء عند التسجيل للحصول على بطاقة
            الخصم، وتشمل المعلومات التي يتم جمعها: الاسم، العنوان، البريد
            الإلكتروني، رقم الهاتف، وتفاصيل الدفع.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            استخدام المعلومات الشخصية
          </h3>
          <p className="text-[12px] md:text-[14px]">
            يتم استخدام المعلومات الشخصية للأغراض التالية: - إصدار وتجديد بطاقة
            الخصم. - تحسين تجربة المستخدم وتقديم خدمات مخصصة. - التواصل مع
            العميل بشأن العروض الخاصة والتحديثات.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            مشاركة المعلومات
          </h3>
          <p className="text-[12px] md:text-[14px]">
            نحن لا نقوم بمشاركة أو بيع المعلومات الشخصية لأي طرف ثالث، باستثناء
            الحالات التي تقتضيها القوانين المحلية أو بموافقة مسبقة من العميل.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            الأمان
          </h3>
          <p className="text-[12px] md:text-[14px]">
            نلتزم بتأمين المعلومات الشخصية من الوصول غير المصرح به أو التعديل أو
            الإفشاء غير القانوني. يتم تخزين البيانات في خوادم آمنة ومعايير عالية
            من التشفير.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            الحق في التعديل أو الحذف
          </h3>
          <p className="text-[12px] md:text-[14px]">
            يحق للعميل طلب تعديل أو حذف أي من بياناته الشخصية في أي وقت من خلال
            التواصل مع خدمة العملاء.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            التعديلات على سياسة الخصوصية
          </h3>
          <p className="text-[12px] md:text-[14px]">
            نحتفظ بحق تعديل سياسة الخصوصية هذه في أي وقت. سيتم إعلام المستخدمين
            بأي تغييرات جوهرية عبر البريد الإلكتروني أو الموقع الإلكتروني.
          </p>
        </div>
        <div>
          <h1 className="md:text-[32px] text-[20px] font-bold mb-8">
            {" "}
            سياسة الاستبدال والاسترجاع{" "}
          </h1>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            {" "}
            مقدمة
          </h3>
          <p className="text-[12px] md:text-[14px]">
            نهدف إلى ضمان رضا عملائنا من خلال تقديم خدمة بطاقة الخصم على الخدمات
            الطبية. إذا كنت غير راضٍ عن البطاقة أو واجهت أي مشكلة، نرحب بتقديم
            طلب للاستبدال أو الاسترجاع وفقًا للشروط المحددة أدناه.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            {" "}
            سياسة الاسترجاع
          </h3>
          <p className="text-[12px] md:text-[14px]">
            *فترة الاسترجاع*: لا يمكن للعميل استرجاع قيمة البطاقة في حين اتمام
            الطلب
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            {" "}
            شروط الاسترجاع
          </h3>
          <p className="text-[12px] md:text-[14px]">
            - يجب أن تكون البطاقة غير مستخدمة. - يجب تقديم الطلب خلال فترة
            الاسترجاع المحددة. - يتم استرجاع المبلغ بنفس طريقة الدفع المستخدمة
            أثناء الشراء.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            {" "}
            إجراءات الاسترجاع
          </h3>
          <p className="text-[12px] md:text-[14px]">
            - يجب على العميل تقديم طلب استرجاع عبر البريد الإلكتروني أو الاتصال
            بخدمة العملاء مع توضيح سبب الاسترجاع وتقديم إثبات الشراء. - سيتم
            معالجة طلب الاسترجاع خلال 7-10 أيام عمل من تاريخ الموافقة على الطلب.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px] md:text-[16px]">
            {" "}
            استثناءات
          </h3>
          <p className="text-[12px] md:text-[14px]">
            - لا يتم استرجاع أو استبدال البطاقة إذا تم استخدامها للحصول على أي
            خدمة طبية. - لا يمكن استرجاع أو استبدال البطاقة بعد انتهاء فترة
            الصلاحية المحددة. - أي رسوم إدارية أو تكاليف إضافية مرتبطة بإصدار
            البطاقة قد لا تكون قابلة للاسترجاع. - لا يمكن استرجاع البطاقة في
            حالة اتمام الطلب
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px]   md:text-[16px]">
            {" "}
            كيفية تقديم طلب الاسترجاع أو الاستبدال
          </h3>
          <p className="text-[12px] md:text-[14px]">
            لطلب استرجاع أو استبدال البطاقة، يُرجى التواصل مع خدمة العملاء عبر
            البريد الإلكتروني: [contact@mohmsa.com] يُرجى تقديم تفاصيل الطلب
            ورقم البطاقة وإثبات الشراء لضمان معالجة طلبك بشكل صحيح.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-[#47BECC] font-bold text-[14px]   md:text-[16px]">
            {" "}
            التعديلات على سياسة الاسترجاع والاستبدال
          </h3>
          <p className="text-[12px] md:text-[14px]">
            نحتفظ بالحق في تعديل هذه السياسة في أي وقت. سيتم إخطار العملاء بأي
            تغييرات من خلال الموقع الإلكتروني أو البريد الإلكتروني.
          </p>
        </div>
      </div>
      {/* <div className="w-[30%] mr-[200px] ">
        <img src={terms} alt="" />
      </div> */}
    </div>
  );
};

export default Terms;
