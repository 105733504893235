import React, { useEffect, useTransition, startTransition } from "react";
import heading from "../../../assets/images/heading.png";
import useApi from "../../hooks/useApi";
import config from "../../Services/config";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
const PriceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { data, fetchData } = useApi();
  const [isPending, startTransition] = useTransition(); // React Transition Hook
  // console.log(data);
  const annualWithTaxAll = data?.body[0]?.annual_fee_with_tax;
  const feeWithTax = Math.floor(annualWithTaxAll * 100) / 100;
  // console.log(feeWithTax);

  const handleButtonClick = (path) => {
    if (user) {
      // Navigate directly if the user is logged in
      startTransition(() => {
        navigate(path, { replace: true });
      });
    } else {
      // Redirect to login and save the intended path if the user is not logged in
      startTransition(() => {
        navigate("/register", {
          replace: true,
          state: { from: location }, // Saves the intended route in location state
        });
      });
    }
  };

  const baseUrl = config.apiUrl;
  useEffect(() => {
    const apiUrl = `${baseUrl}/card-types`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetchData(apiUrl, options);
    window.scrollTo(0, 0);
  }, []);
  // console.log(data?.body[0]);
  const handleLoginNavigation = () => {
    const currentUrl = window.location.pathname + window.location.search;
    navigate(`/register?redirect=${encodeURIComponent(currentUrl)}`, {
      replace: true,
    });
  };
  const handleRoute = () => {
    startTransition(() => {
      navigate("/register", { replace: true });
    });
  };
  return (
    <div className="price-list  w-full  xl:pb-[60px]  bg-white">
      <div className="mx-auto w-[370px] sm:w-[600px] md:w-[700px] lg:w-[980px] xl:w-[1200px]">
        <div className="relative mb-[30px]  mx-auto ">
          <img
            className="absolute left-[50%] top-[-20px]"
            src={heading}
            alt=""
          />
          <h1 className="text-[#1E2865] font-bold text-[24px] lg:text-[36px] mb-8 text-center">
            البطــــــــــاقــــــــــات
          </h1>
          <p className="text-center text-[16px] lg:text-[20px]">
            {" "}
            عرض البطاقات الحصري
          </p>
        </div>

        <div className="flex justify-center  mx-auto   ">
          {/* <div className="card-orders bg-[#e5faff] xl:w-[406px] h-[688px] text-center">
            <div>
              <h1 className="text-[40px] text-[#4C94D3]">مميـــــزة</h1>
              <h2 className="font-extrabold text-[36px] text-[#1E2865]">150</h2>
              <h2 className="text-[#30ECAC] text-[26px]">
                <del>200</del>
              </h2>
              <h2 className="font-extrabold text-[36px] text-[#1E2865]">
                {" "}
                ريـــــــال
              </h2>
            </div>
            <div className="features-orders mb-4">
              <button className="send-req w-[100%] h-[44px] text-white font-bold mb-5">
                ارسال الطلب
              </button>
              <div className="text-right mb-4 leading-[2]">
                <p>. مميزات البطاقه هنا</p>
                <p>. مميزات البطاقه هنا</p>
                <p>. مميزات البطاقه هنا</p>
              </div>
            </div>
            <div className="card-features mt-4">
              <h1 className="text-right text-[20px] font-bold text-[#2E2E2E]">
                تشمل البطاقه{" "}
              </h1>
              <div className="text-right">
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
              </div>
            </div>
          </div> */}
          <div className="card-orders  bg-[#e5faff]  xl:w-[406px]    h-[688px] text-center">
            <div>
              <h1 className="text-[40px] text-[#4C94D3]">
                {data?.body[0].name}
              </h1>
              <div className="flex justify-center items-center mr-4">
                <h2 className="font-extrabold text-[36px] text-[#1E2865]">
                  {data?.body[0]?.annual_fee_with_tax}
                </h2>
                <span className="text-[11px] mt-2">شامل الضريبة</span>
              </div>
              {/* <h2 className="text-[#30ECAC] text-[26px]">
                <del>200</del>
              </h2> */}

              <h2 className="font-extrabold text-[36px] text-[#1E2865]">
                {" "}
                ريـــــــال
              </h2>
            </div>
            <div className="features-orders mb-4">
              <>
                {/* {user ? (
                  <button
                    className="send-req w-[100%] h-[44px] text-white font-bold mb-5"
                    onClick={() =>
                      handleButtonClick(`/card-types/${data?.body[0]?.id}`)
                    }
                  >
                    اطلب البطاقة
                  </button>
                ) : (
                  <button
                    className="send-req w-[100%] h-[44px] text-white font-bold mb-5"
                    disabled={!data}
                    onClick={() => handleButtonClick(`/register`)}
                  >
                    اطلب البطاقة
                  </button>
                )} */}
                <button
                  className="send-req w-full h-[44px] text-white font-bold mb-5"
                  disabled={!data} // Disable button if no data
                  onClick={() =>
                    handleButtonClick(`/card-types/${data?.body[0]?.id}`)
                  }
                >
                  اطلب البطاقة
                </button>
              </>

              {/* <Link to={`/card-types/${data?.body[0]?.id}`}>
                <button className="send-req   w-[100%] h-[44px] text-white font-bold mb-5">
                  اطلب البطاقة
                </button>
              </Link> */}

              <div className="text-right mb-4 leading-[2]">
                <p>{data?.body[0].description}</p>
              </div>
            </div>
            <div className="card-features mt-4">
              <h1 className="text-right text-[20px] font-bold text-[#2E2E2E]">
                مميزات البطاقة{" "}
              </h1>
              <div className="text-right">
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">
                    {data?.body[0].features}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="card-orders bg-[#e5faff]  xl:w-[406px] h-[688px] text-center">
            <div>
              <h1 className="text-[40px] text-[#4C94D3]">عاديــــــة </h1>
              <h2 className="font-extrabold text-[36px] text-[#1E2865]">150</h2>
              <h2 className="text-[#30ECAC] text-[26px]">
                <del>200</del>
              </h2>
              <h2 className="font-extrabold text-[36px] text-[#1E2865]">
                {" "}
                ريـــــــال
              </h2>
            </div>
            <div className="features-orders mb-4">
              <button className="send-req w-[100%] h-[44px] text-white font-bold mb-5">
                ارسال الطلب
              </button>
              <div className="text-right mb-4 leading-[2]">
                <p>. مميزات البطاقه هنا</p>
                <p>. مميزات البطاقه هنا</p>
                <p>. مميزات البطاقه هنا</p>
              </div>
            </div>
            <div className="card-features mt-4">
              <h1 className="text-right text-[20px] font-bold text-[#2E2E2E]">
                تشمل البطاقه{" "}
              </h1>
              <div className="text-right">
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
                <p>
                  <span className="text-#2B1C50]">✓</span>{" "}
                  <span className="text-[#2e2e2e]">مميزات البطاقه هنا</span>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PriceList;
