import React from "react";
import { Link } from "react-router-dom";
import notFoundImg from "../../assets/images/404.png";
const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <img className="mt-8 mb-4 h-[60%]" src={notFoundImg} alt="" />
      <Link to="/" className="text-blue-500 hover:underline">
        الرجوع للصفحة الرئيسية
      </Link>
    </div>
  );
};

export default NotFoundPage;
