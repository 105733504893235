import React from "react";
import axios from "axios";
import config from "../../../Services/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const ApplePayButton = ({ amount }) => {
  const navigate = useNavigate();
  const token = Cookies.get("authToken");
  // console.log(token);
  console.log(amount);
  const baseUrl = config.apiUrl;
  // console.log("baseUrl", baseUrl);
  let transactionId;
  const authorizeMerchant = async (validationURL) => {
    try {
      const response = await axios.post(
        `${baseUrl}/cards/order`,
        {
          amount: amount,
          currency: "SAR",
          card_type_id: 1,
          is_apple_pay: 1,
          is_mobile: 0,
          validation_url: validationURL,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            lang: "ar",
          },
        }
      );
      // console.log("response", response.data.body.validation_data);
      // Assuming the response contains the required merchant session
      // Assuming response.data.body.validation_data is a JSON string
      const merchantSessionString = response.data.body.validation_data;
      transactionId = response.data?.body?.transaction_id;
      console.log("transactionId", transactionId);
      // console.log("merchantSessionString", merchantSessionString);
      // Parse the string into an object
      const merchantSession = JSON.parse(merchantSessionString);
      // console.log("Merchant session:after parsing", merchantSession);
      // console.log(merchantSession);
      return merchantSession;
    } catch (error) {
      console.error("Error validating merchant:", error);
      throw new Error("Merchant validation failed");
    }
  };
  const sendPaymentToBackend = async (paymentToken, transactionId) => {
    try {
      // console.log("order idddddd", transactionId);

      const response = await axios.post(
        `${baseUrl}/cards/apple-pay-web`,
        {
          token: paymentToken,
          transaction_id: transactionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            lang: "ar",
          },
        }
      );

      if (response.status === 200) {
        navigate("/payment/success-payment"); // Navigate to success page on successful response
      } else {
        navigate("/payment/failed-payment"); // Navigate to fail page on unsuccessful response
      }
    } catch (error) {
      console.error("Error sending payment to backend:", error);
      navigate("/payment/failed-payment"); // Navigate to fail page on error
    }
  };
  const initiateApplePay = async () => {
    // console.log("Initiating Apple Pay...");

    if (window.PaymentRequest) {
      const methodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3, // Apple Pay API version
            merchantIdentifier: "merchant.com.mohmsa.mohmweb", // Your Merchant ID
            merchantCapabilities: [
              "supports3DS",
              "supportsCredit",
              "supportsDebit",
            ],
            supportedNetworks: ["visa", "masterCard", "mada"], // Payment networks you support
            countryCode: "SA", // Your country code
            currencyCode: "SAR", // Your currency code
          },
        },
      ];

      const details = {
        total: {
          label: "MOHM Web",
          amount: {
            currency: "SAR",
            value: amount,
          },
        },
      };

      let paymentResponse;

      try {
        const request = new PaymentRequest(methodData, details);
        // console.log("request", request);

        request.onmerchantvalidation = async (event) => {
          // console.log("Merchant Validation Event:", event);
          // console.log("Methods available in event:", Object.keys(event));

          const validationURL = event.validationURL;
          // console.log("Merchant Validation URL:", validationURL);

          try {
            const merchantSession = await authorizeMerchant(validationURL);
            // console.log("Merchant session to be completed:", merchantSession);

            // Complete the validation with the correct merchant session
            event.complete(merchantSession);
          } catch (error) {
            console.error("Merchant validation failed:", error);
            event.complete(null);
          }
        };

        const canMakePayment = await request.canMakePayment();
        // console.log("canMakePayment", canMakePayment);
        if (canMakePayment) {
          // alert(1);
          paymentResponse = await request.show();
          // alert(2);
          // console.log("paymentResponsesssssss", paymentResponse);

          if (!paymentResponse) {
            console.log("User canceled payment");
            return;
          }
          const paymentToken = paymentResponse.details.token;
          // console.log("paymentToken", paymentToken);

          await paymentResponse.complete("success");
          await sendPaymentToBackend(paymentToken, transactionId);

          // console.log("Payment completed successfully");
        } else {
          console.log("User cannot make payments with Apple Pay");
        }
      } catch (error) {
        console.error("Payment processing error:", error.message || error);

        if (paymentResponse) {
          await paymentResponse.complete("fail");
        }
      }
    } else {
      console.log("Payment Request API is not supported in this browser.");
    }
  };

  return (
    <button
      className="w-full mt-4 mx-2 payment-button text-[24px]"
      id="apple-pay-button"
      style={{
        WebkitAppearance: "-apple-pay-button",
        height: "64px",
        backgroundImage:
          "-webkit-named-image(apple-pay-with-text-white, apple-pay-with-text-black)",
        backgroundSize: "100% 60%",
        backgroundOrigin: "content-box",
        backgroundRepeat: "no-repeat",
        padding: "10px",
        width: "100%",
      }}
      onClick={initiateApplePay}
    ></button>
  );
};

export default ApplePayButton;
